<template>
  <locale-router-link
    :to="`news/${item.id}`"
    tag="div"
    v-if="item"
    class="news-full__item"
  >
    <img v-lazy="getNewsImg()" alt="News image" class="news-full__img" />
    <time class="news-full__time">
      {{ getDate(item) }}
    </time>
    <h4 class="news-full__subtitle">
      {{ item.attributes.Header }}
    </h4>
  </locale-router-link>
</template>

<script>
export default {
  name: "NewsAsideCard",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localMonth: {
        января: "қаңтар",
        февраля: "ақпан",
        марта: "наурыз",
        апреля: "сәуір",
        мая: "мамыр",
        июня: "маусым",
        июля: "шілде",
        августа: "тамыз",
        сентября: "қыркүйек",
        октября: "қазан",
        ноября: "қараша",
        декабря: "желтоқсан",
      },
    };
  },
  methods: {
    getNewsImg() {
      const imgUrl = this.item?.attributes?.image?.data?.attributes?.url;
      return process.env.VUE_APP_API_URL + imgUrl;
    },
    getDate(item) {
      const dateFromStr = new Date(item.attributes.publishedAt);
      let dateRU = new Intl.DateTimeFormat("ru-Ru", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(dateFromStr);
      let date = dateRU.split(" ");
      let dateKZ = date[0] + " " + this.localMonth[date[1]] + " " + date[2];
      if (this.$i18n.locale === "kz") {
        return dateKZ;
      } else {
        return dateRU;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
